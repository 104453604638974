import React from 'react';
import Banner from './components/Banner';
import Nav from './components/Nav';
import Skill from './components/Skill';
import Projects from './components/Project';
import Experience from './components/Experience';

const App = () => {
  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      <Banner />
      <Nav />
      <Skill />
      <Projects />
    </div>
  );
};

export default App;
