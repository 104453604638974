import calculatorImg from "../projects/calculator.png";
import emailImg from "../projects/email.png";
import gpaImg from "../projects/gpa.png";
import leetcodeImg from "../projects/leetcode.png";
import plannerImg from "../projects/planner.png";
import recipeImg from "../projects/recipe.png";
import venueImg from "../projects/venue.png";
import celiaImg from "../projects/celia.png";

const portfolios = [
  {
    id: "00",
    imgUrl: celiaImg,
    title: "Celia AI",
    subtitle: "Web Application",
    description:
      "Voice-based AI interview bot to help user practice behavioral interviews based on inputted job description. AI interviewer Celia will ask interview question in both voice and text form; users then reply with their voice recording and will be transcribed to text form. Users repeats this process to experience a real interview session. Feedback can then be generated after the interview is completed.",
    technologies: ["React", "FastAPI", "Firebase", "OpenAI APIs", "MUI", "Tailwind"],
    siteUrl: "https://github.com/food0903/AI-Job-Interview",
  },
  {
    id: "01",
    imgUrl: venueImg,
    title: "Venue",
    subtitle: "Mobile Application",
    description:
      "App for users to stay in the loop of different events around them. Users can host and participate in public and private events with the included map to see events near user's location. Allow users to post pictures and comments on attending events.",
    technologies: ["Flutter", "Dart", "Google APIs", "Firebase"],
    siteUrl: "https://github.com/trungtran1234/Venue",
  },
  {
    id: "01",
    imgUrl: recipeImg,
    title: "Recipe Generator",
    subtitle: "Web Application",
    description:
      "Generates recipes based on ingredients you have, diet restrictions, and personal preferences. Recipes are labeled with their nutritional information, meal type, cuisine, and time required to cook. Includes pantry system that keep track of user's ingredients and their nutritional information.",
    technologies: ["JavaScript", "Python", "React", "Flask", "Bootstrap", "SQLAlchemy", "MySQL", "AWS"],
    siteUrl: "https://github.com/trungtran1234/recipe_generator",
  },
  {
    id: "02",
    imgUrl: plannerImg,
    title: "Planner App",
    subtitle: "Desktop Application",
    description:
      "A planner app that allows user to create, edit, and delete events in order to keep track of their schedule. Includes a weekly view to see all events for a given week with the time synced to the user's timezone.",
    technologies: ["Java", "JavaFx", "SQLite"],
    siteUrl: "https://github.com/trungtran1234/java_planner",
  },
  {
    id: "03",
    imgUrl: leetcodeImg,
    title: "LeetCode Study Bot",
    subtitle: "Discord Bot",
    description:
      "A Discord bot that help users master LeetCode's Blind 75 question set. The bot will ask a random Blind 75 LeetCode question and users can submit their approach to the problem. The bot will then check the user's solution and provide feedback.",
    technologies: ["Python", "Discord API", "OpenAI API", "AWS"],
    siteUrl: "https://discord.com/oauth2/authorize?client_id=1140431775793938534&permissions=93184&scope=bot",
  },
  {
    id: "04",
    imgUrl: emailImg,
    title: "Email Application",
    subtitle: "Web Application",
    description:
      "My first fullstack project (I learned so much) - An email application that allow users to send and receive emails from other users. Users can bookmark emails, add users to their contact list, and filter search emails. Includes a todo list feature that allows users to create and delete tasks.",
    technologies: ["Python", "Flask", "HTML", "CSS", "Bootstrap", "SQLAlchemy", "SQLite"],
    siteUrl: "https://github.com/trungtran1234/EmailApp",
  },
  {
    id: "05",
    imgUrl: calculatorImg,
    title: "IOS Calculator Clone",
    subtitle: "Desktop Application",
    description:
      "After taking many Java classes (DSA), I wanted to make something simple out of Java! - An IOS calculator clone. Allows users to perform arithmetic operations.",
    technologies: ["Java", "JavaFX"],
    siteUrl: "#",
  },

  {
    id: "06",
    imgUrl: gpaImg,
    title: "GPA Calculator",
    subtitle: "Web Application",
    description:
      "My first coding project! A GPA calculator that allows users to calculate their GPA based on their grades. Used to help high school students keep track of their GPA.",
    technologies: ["JavaScript"],
    siteUrl: "https://studio.code.org/projects/applab/aeU4kHOry3JPmgDHkZHF90zUaC-E3EuX30szqvBdmms",
  },
];

export default portfolios;