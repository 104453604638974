import React from 'react';
import { BsPerson } from "react-icons/bs";
import { VscTools } from "react-icons/vsc";
import { LuBrainCog } from "react-icons/lu";

import {Link} from 'react-scroll';

const Nav = () => {
  return (
    <nav className='fixed top-1 lg:top-1 w-full overflow-hidden z-40'>
      <div className="container mx-auto">
        <div className='w-[100%] bg-black/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[560px] mx-auto px-5 flex justify-between text-white/50 items-center'>
          <Link to='home' activeClass='active' smooth={true} spy={true} className='cursor-pointer flex items-center w-[140px] h-[60px] space-x-2 justify-center lg:justify-start'>
            <BsPerson/> <h1 className="hidden lg:inline text-[20px]">About Me</h1>
          </Link>
          <Link to='skill' activeClass='active' smooth={true} spy={true} className='cursor-pointer flex items-center w-[140px] h-[60px] space-x-2 justify-center lg:justify-start'>
            <LuBrainCog/> <h1 className="hidden lg:inline text-[20px]">My Skills</h1>
          </Link>
          <Link to='project' activeClass='active' smooth={true} spy={true} className='cursor-pointer flex items-center w-[140px] h-[60px] space-x-2 justify-center lg:justify-start'>
            <VscTools/> <h1 className="hidden lg:inline text-[17px]">My Projects</h1>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Nav;
