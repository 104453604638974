import React from 'react'
import Html from '../assets/html.png';
import Css from '../assets/css.png';
import Javascript from '../assets/javascript.png';
import ReactImage from '../assets/react.png';
import Flask from '../assets/flask.png';
import Python from '../assets/python.png';
import AWS from '../assets/aws.png';
import Bootstrap from '../assets/bootstrap.png';
import Tailwind from '../assets/tailwind.png';
import Sql from '../assets/sql.png';
import Git from '../assets/git.png';
import Postman from '../assets/postman.png';
import Java from '../assets/java.png';
import Shell from '../assets/shell.png';
import Svelte from '../assets/svelte.png';
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import fastAPI from '../assets/fastapi.png';
import TypeScript from '../assets/typescript.png';
import Docker from '../assets/docker.png';
import Flutter from '../assets/flutter.png';
import Firebase from '../assets/firebase.png';
import Dart from '../assets/dart.png';
import Nodejs from '../assets/nodejs.png';
import Express from '../assets/express.png';

const Skill = () => {
  const skills = [
    {
      id: 1,
      src: Python,
      title: 'Python',
    },
    {
      id: 1,
      src: Java,
      title: 'Java',
    },
    {
      id: 1,
      src: Javascript,
      title: 'JavaScript',
    },
    {
      id: 1,
      src: TypeScript,
      title: 'TypeScript',
    },
    {
      id: 1,
      src: ReactImage,
      title: 'React',
    },
    {
      id: 1,
      src: Svelte,
      title: 'Svelte',
    },
    {
      id: 1,
      src: Nodejs,
      title: 'Node.js',
    },
    {
      id: 1,
      src: Express,
      title: 'Express',
    },
    {
      id: 1,
      src: Html,
      title: 'HTML',
    },
    {
      id: 1,
      src: Css,
      title: 'CSS',
    },
    {
      id: 1,
      src: Sql,
      title: 'SQL',
      style: 'shadow-orange-500'
    },
    {
      id: 1,
      src: AWS,
      title: 'AWS',
      style: 'shadow-orange-500'
    },
    {
      id: 1,
      src: Firebase,
      title: 'Firebase',
    },
    {
      id: 1,
      src: Flask,
      title: 'Flask',
    },
    {
      id: 1,
      src: fastAPI,
      title: 'FastAPI',
    },
    {
      id: 1,
      src: Flutter,
      title: 'Flutter',
    },
    {
      id: 1,
      src: Dart,
      title: 'Dart',
    },
    {
      id: 1,
      src: Postman,
      title: 'Postman',
    },
    {
      id: 1,
      src: Bootstrap,
      title: 'Bootstrap',
    },
    {
      id: 1,
      src: Tailwind,
      title: 'Tailwind',
    },
    {
      id: 1,
      src: Git,
      title: 'Git',
    },
    {
      id: 1,
      src: Shell,
      title: 'Shell',
    },
    {
      id: 1,
      src: Docker,
      title: 'Docker',
    },
    
  ]
  return (
    <div className='section' id='skill'>
      <container className="mx-auto mt-[10px] flex flex-col w-full h-full p-[60px] lg:p-[100px]">
          <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }}  className="text-[16px] lg:text-[36px] pt-0 pb-0 lg:pb-6 ml-[50px] lg:ml-[60px] lg:text-left font-semibold">Languages & Technologies:</motion.div>
          <div className="w-full grid grid-cols-3 lg:grid-cols-7 gap-4 lg:gap-8 text-center py-8 px-12 lg:px-0">
              {skills.map(({ id, src, title}) => (
               <motion.div 
               variants={fadeIn('right', 0.3)} 
               initial="hidden" 
               whileInView="show" 
               viewport={{ once: true, amount: 0.7 }}
               key={id} 
               className="duration-500 py-2 rounded-lg"
           >
               <div className="hover:scale-150 duration-700">
                   <img src={src} alt={title} className="w-20 mx-auto" />
                   <p className="text-[10px] lg:text-[20px]">{title}</p>
               </div>
           </motion.div>
            ))}
            </div>
      </container >
    </div >
  )
}

export default Skill