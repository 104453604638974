import React from 'react'
import data from '../assets/data/portfolioData'
import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { fadeIn } from '../variants'
import Nav from './Nav'

const Project = () => {
  
  const modalVariants = {
    hidden: {
      opacity: 0,
      y: 0
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const Modal = ({ project, isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-backdrop bg-white z-40 bg-opacity-40 top-0 left-0 fixed w-full h-full flex">
<motion.div
        className="modal lg:max-w-[600px] absolute top-1/2 left-1/2 z-50 bg-white rounded-[8px] p-5"
        style={{ translateX: '-50%', translateY: '-50%' }}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden">
          <button className='lg:ml-[520px] ml-62 border-black border-solid absolute lg:top-[1.2rem] bg-red-500 px-4 py-1 rounded-lg font-bold text-black opacity-90 hover:pl-5 hover:pr-5 hover:pb-2 hover:opacity-100' onClick={onClose}>X</button>
          <img className='min-w-80 rounded-[8px] lg:max-w-[560px]' src={project.imgUrl} alt={project.title} />
          <h2 className='text-black font-bold text-[28px] lg:text-[28px]'>{project.title}</h2>
          <p className='text-black text-[20px] leading-[1]'>{project.description}</p>
          <div className='mt-3 flex items-center gap-0.5 lg:gap-3 flex-wrap'>
            <div className="text-black text-[16px] leading-[1] lg:text-[18px] text-[700] ">
              Technologies used:
            </div>
            {
              project.technologies.map((item, index) => (
                <span key={index} className="bg-gray-200 py-1 px-2 rounded-lg text-black text-[13px] lg:text-[16px] leading-[1] lg:leading-0">{item}</span>
              )
            )}
          </div>
          <a href={project.siteUrl} target='_blank' >
          <button className='btn btn-md hover:scale-110 duration-500 hover:text-black mt-[15px] hover:font-semibold text-[8px] lg:text-[12px] py-[1px] px-[6px] lg:py-1 lg:px-3 text-center align-middle'>View GitHub</button>
          </a>
        </motion.div>
      </div>
    );
  };
  
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(false);
    if (selectedProject) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [selectedProject]); 

const openModal = (project) => {
  setSelectedProject(project);
  setIsModalOpen(true);
};

const closeModal = () => {
  setSelectedProject(null);
  setIsModalOpen(false);
};
  return (
    <section className='section' id='project'>
        {!isModalOpen && <Nav />}
      <div className="container mt-[100px] mx-auto">
        <motion.div variants={fadeIn('right', 0.1)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='font-semibold leading-tight text-[30px] lg:text-[40px] mb-6'>My Projects</motion.div>
        <div variants={fadeIn('left', 0.3)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.7 }} className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
          {data.map((project, index) => (
            <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} viewport={{ once: true, amount: 0.1 }} key={index} className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-30 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={project.imgUrl} alt={project.title} />
              <div className='absolute -bottom-full lg:left-12 left-7 lg:group-hover:bottom-20 group-hover:bottom-12 transition-all duration-500 z-40'>
                <span className='font-secondary lg:text-[18px] text-[12px] text-gray-400'>{project.subtitle}</span>
              </div>
              <div className='absolute -bottom-full lg:left-12 left-7 lg:group-hover:bottom-14 group-hover:bottom-8 transition-all duration-700 z-40'>
                <span className='lg:text-[24px] text-[20px] text-white'>{project.title}</span>
              </div>
              <div className='absolute -bottom-full right-7 lg:right-12 lg:group-hover:bottom-14 group-hover:bottom-8 transition-all duration-700 z-40'>
                <span className='text-[10px] lg:text-[20px] bg-yellow-500/35 px-4 py-2 rounded-lg text-white hover:font-bold hover:px-5 hover:py-3 duration-700 '><button onClick={() => openModal(project)}>View Details</button></span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <AnimatePresence>
      {selectedProject && (
        <Modal project={selectedProject} isOpen={!!selectedProject} onClose={closeModal} />
      )}
    </AnimatePresence>
    </section>
  )
}

export default Project